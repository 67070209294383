import { FC, PropsWithChildren, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext, Role, RoutesPath } from '../../utils';

export const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useContext(AuthContext);

  return user ? <>{children}</> : <Navigate to={`/${RoutesPath.LOGIN}`} replace />;
};

export const AdminRoute: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useContext(AuthContext);

  return user?.role === Role.Admin ? <>{children}</> : <Navigate to={`/${RoutesPath.LOGIN}`} replace />;
};

export const PartnerRoute: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useContext(AuthContext);

  return user?.role === Role.Partner ? <>{children}</> : <Navigate to={`/${RoutesPath.LOGIN}`} replace />;
};
