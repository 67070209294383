import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Code, SelectedCode } from '../../utils';
import { Checkbox, TablePagination } from '@mui/material';
import { EnhancedTableHead, Order, HeadCell } from './EnhancedTableHead';
import { useTranslation } from 'react-i18next';

interface CodesTableProps {
  codes: Code[];
  headCells: HeadCell[];
  selectedCodes: SelectedCode[];
  setSelectedCodes: React.Dispatch<React.SetStateAction<SelectedCode[]>>;
}

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const CodesTable: FC<CodesTableProps> = ({ codes, headCells, selectedCodes, setSelectedCodes }) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Code>('amount');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const { t } = useTranslation('translation', { keyPrefix: 'CodesTable' });

  useEffect(() => {
    setSelectedCodes([]);
  }, [codes]);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = codes.map(({ code, password }) => ({ code, password }));
      return setSelectedCodes(newSelected);
    }
    setSelectedCodes([]);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as keyof Code);
  };

  const isSelected = (selectedCode: string) => Boolean(selectedCodes.find(({ code }) => code === selectedCode));

  const handleSelectCode = (selectedCode: string, password: string) => {
    const selectedIndex = selectedCodes.findIndex(({ code }) => code === selectedCode);
    let newSelected: SelectedCode[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCodes, { code: selectedCode, password });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCodes.slice(1));
    } else if (selectedIndex === selectedCodes.length - 1) {
      newSelected = newSelected.concat(selectedCodes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedCodes.slice(0, selectedIndex), selectedCodes.slice(selectedIndex + 1));
    }

    setSelectedCodes(newSelected);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <EnhancedTableHead
            numSelected={selectedCodes.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={codes.length}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(codes, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(({ code, ticker, amount, status, password }) => {
                const isItemSelected = isSelected(code);

                return (
                  <TableRow
                    key={code}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    hover
                    onClick={() => handleSelectCode(code, password)}
                    role="checkbox"
                    tabIndex={-1}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {ticker}
                    </TableCell>
                    <TableCell align="right">{amount}</TableCell>
                    {status && <TableCell align="right">{status}</TableCell>}
                    <TableCell align="right">{code}</TableCell>
                    {password && <TableCell align="right">{password}</TableCell>}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={codes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('labelRowsPerPage')}
      />
    </>
  );
};
