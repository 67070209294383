import { useRef, useState, useEffect } from 'react';
import QrScanner from 'qr-scanner';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ScannerProps {
  open: boolean;
  onClose: () => void;
  onScan: (result: QrScanner.ScanResult) => void;
}

export const QrModal: React.FC<ScannerProps> = ({ open, onClose, onScan }) => {
  const video = useRef<HTMLVideoElement>(null);
  const [qrScanner, setQrScanner] = useState<QrScanner>();
  const { t } = useTranslation('translation', { keyPrefix: 'QrModal' });

  const handleScan = (result: QrScanner.ScanResult) => {
    onScan(result);
    onClose();
  };

  useEffect(() => {
    if (!open) {
      qrScanner?.stop();
      qrScanner?.destroy();
      setQrScanner(undefined);
    }
  }, [open, qrScanner]);

  useEffect(() => {
    setTimeout(() => {
      if (video.current && open && !qrScanner) {
        const qrScanner = new QrScanner(video.current, result => handleScan(result), {
          highlightScanRegion: true,
        });
        qrScanner.start();
        setQrScanner(qrScanner);
      }
    }, 0);
    // eslint-disable-next-line
  }, [video.current, open, qrScanner]);

  return (
    <Dialog
      open={open}
      transitionDuration={0}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <video ref={video} style={{ width: '100%' }}>
          <track kind="captions" />
        </video>
      </DialogContent>
    </Dialog>
  );
};
