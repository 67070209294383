export enum RoutesPath {
  HOME = '/',
  LOGIN = 'login',
  ACCOUNTS = 'accounts',
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  PASSWORD_RESET = 'passwordReset',
  PASSWORD_FORGOT = 'forgot-password',
  PARTNERS = 'partners',
  MERCHANTS = 'merchants',
}

export enum Role {
  Admin = 'admin',
  Partner = 'partner',
  Merchant = 'merchant',
}
