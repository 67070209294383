import { FC, useEffect, useRef, useState } from 'react';
import { Alert, AlertProps, List, ListItemText } from '@mui/material';

interface InlineNotificationProps extends AlertProps {
  ttl?: number;
  open?: boolean;
  onClose?: () => void;
  list?: string[];
}

export const InlineNotification: FC<InlineNotificationProps> = ({
  ttl,
  open,
  onClose,
  severity,
  list,
  children,
  ...rest
}) => {
  const timer = useRef<NodeJS.Timeout>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!open);

    if (open && ttl) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setIsOpen(false);
        onClose?.();
      }, ttl);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [open]);

  return isOpen ? (
    <Alert sx={{ margin: '10px 0' }} severity={severity ?? 'success'} {...rest}>
      {list?.length && list?.length > 1 ? (
        <List
          sx={{
            listStyleType: 'disc',
            pl: 2,
          }}
        >
          {list.map(text => (
            <ListItemText key={text} primary={text} sx={{ display: 'list-item' }} />
          ))}
        </List>
      ) : null}

      {list?.length && list?.length === 1 ? list[0] : null}

      {children}
    </Alert>
  ) : null;
};
