import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FC, useState } from 'react';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';

export const PasswordField: FC<TextFieldProps> = ({ value, onChange, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      value={value}
      fullWidth
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
