import { FC, PropsWithChildren, useState } from 'react';
import { IconButton, ListItem, ListItemText, Switch, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

type AccountListItemProps = PropsWithChildren & {
  email: string;
  disableActions?: boolean;
  disabled?: boolean;
  expandable?: boolean;
  handleDelete?: () => void;
  onAccountClick?: () => void;
  handleDisabledChange?: () => void;
  padding?: string;
};

export const AccountListItem: FC<AccountListItemProps> = ({
  handleDelete,
  disableActions,
  email,
  disabled,
  onAccountClick,
  handleDisabledChange,
  padding,
  expandable = false,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'AccountListItem' });

  return (
    <>
      <ListItem
        divider
        secondaryAction={
          handleDelete && (
            <IconButton edge="end" aria-label="delete" disabled={disableActions} onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          )
        }
        sx={{ ...(padding && { paddingLeft: padding }) }}
      >
        {expandable ? (
          <IconButton disabled={!children} onClick={() => setIsExpanded(!isExpanded)} sx={{ marginRight: '10px' }}>
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        ) : null}
        <ListItemText
          primary={email}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', ...(onAccountClick && { cursor: 'pointer' }) }}
          onClick={() => onAccountClick?.()}
        />
        {handleDisabledChange && (
          <Tooltip title={disabled ? t('tooltip.disabled') : t('tooltip.active')} placement="top">
            <Switch edge="end" checked={!disabled} disabled={disableActions} onChange={handleDisabledChange} />
          </Tooltip>
        )}
      </ListItem>
      {isExpanded && children}
    </>
  );
};
