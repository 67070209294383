import { FC, useContext, useEffect, useState } from 'react';
import { AccountListItem, CreateUserEntity, InlineNotification, Loader, PaperWrapper } from '../../components';
import { PageTemplate } from '../PageTemplate/PageTemplate';
import { CreatePartnerData, PartnerContext, useNotification } from '../../utils';
import { List } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const MerchantsPage: FC = () => {
  const [createLoading, setCreateLoading] = useState(false);
  const [createErrors, setCreateErrors] = useState<string[]>([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [actionErrors, setActionErrors] = useState<string[]>([]);
  const [merchantsLoading, setMerchantsLoading] = useState(true);
  const [merchantsErrors, setMerchantsErrors] = useState<string[]>([]);
  const { merchants, createMerchant, getMerchants, disableUser } = useContext(PartnerContext);
  const { openNotification } = useNotification();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.merchants' });

  const fetchData = async () => {
    try {
      setMerchantsLoading(true);
      await getMerchants();
    } catch ({ message }) {
      setMerchantsErrors(typeof message === 'string' ? [message] : message);
    } finally {
      setMerchantsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreateMerchant = async (data: CreatePartnerData) => {
    try {
      setCreateErrors([]);
      setCreateLoading(true);
      await createMerchant(data);
      openNotification(t('merchantCreatedMessage'));
      await getMerchants();
    } catch ({ message, errors }) {
      const msg = typeof message === 'string' ? [message] : message;
      setCreateErrors(errors ?? msg);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleDisabledChange = async (state: boolean, userId: string) => {
    try {
      setActionErrors([]);
      setActionLoading(true);
      await disableUser(state, userId);
      await getMerchants();
    } catch ({ message }) {
      setActionErrors(typeof message === 'string' ? [message] : message);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <PageTemplate pageTitle={t('title') as string}>
      <CreateUserEntity
        createButtonText={t('buttons.create')}
        errors={createErrors}
        isLoading={createLoading}
        createEntity={handleCreateMerchant}
      />
      {merchantsLoading ? (
        <Loader />
      ) : (
        <PaperWrapper>
          <InlineNotification open={!!merchantsErrors.length} list={merchantsErrors} severity="error" />
          <InlineNotification open={!!actionErrors?.length} list={actionErrors} ttl={5000} severity="error" />
          {merchants.length ? (
            <List>
              {merchants
                .sort((a, b) => a.email.localeCompare(b.email))
                .map(({ email, id, disabled }) => (
                  <AccountListItem
                    key={id}
                    email={email}
                    disabled={disabled}
                    disableActions={actionLoading}
                    handleDisabledChange={() => handleDisabledChange(!disabled, id)}
                  />
                ))}
            </List>
          ) : (
            !merchantsErrors.length && <InlineNotification open list={[t('noMerchantsMessage')]} severity="info" />
          )}
        </PaperWrapper>
      )}
    </PageTemplate>
  );
};
