import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PaperWrapper, Loader, CodeActivationSection, InlineNotification } from '../../components';
import { Account, fetchData } from '../../utils';
import { PageTemplate } from '../PageTemplate/PageTemplate';
import { useTranslation } from 'react-i18next';

export const AccountPage: FC = () => {
  const { accountId } = useParams();
  const [account, setAccount] = useState<Account | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);
  const { t } = useTranslation('translation', { keyPrefix: 'pages.account' });

  const fetchAccount = async () => {
    try {
      const account = await fetchData(`accounts/${accountId}`);
      setAccount(account);
    } catch ({ message, errors }) {
      const messagesArr = Array.isArray(message) ? message : [message];
      setErrors(errors ?? messagesArr);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAccount();
  }, []);

  return (
    <PageTemplate pageTitle={t('title') as string}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PaperWrapper>
            <InlineNotification open={!!errors.length} list={errors} severity="error" />
            {account && <h2 style={{ margin: '0' }}>{account?.email}</h2>}
          </PaperWrapper>
          <CodeActivationSection />
        </>
      )}
    </PageTemplate>
  );
};
