import { ChangeEvent, FC, Fragment, useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Code, PartnerContext } from '../../utils';
import { HeadCell, SimpleTableHead } from '../../components';
import { TablePagination } from '@mui/material';
import { ExpandableTableRow } from './ExpandableTableRow';
import { TotalTableSection } from './TotalTableSection';
import { DashboardFilter } from './DashboardFilter';
import { DateType } from './types';
import { useTranslation } from 'react-i18next';

type ActivatedByMapType = Map<string, Map<string, Code[]>>;
type TotalDataType = Map<string, number>;

interface Codes {
  email: string;
  total: number;
  activatedBy: ActivatedByMapType;
  totalData: TotalDataType;
}

const getTotalSectionData = (codesToRender: Codes[]) =>
  codesToRender.reduce<TotalDataType>((acc, { totalData }) => {
    totalData.forEach((value, key) => {
      const tickerValue = acc.get(key);
      acc.set(key, tickerValue ? tickerValue + value : value);
    });
    return acc;
  }, new Map());

export const AdminDashboardTable: FC = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const { partners, getPartners } = useContext(PartnerContext);
  const [filterEmail, setFilterEmail] = useState('');
  const [fromDate, setFromDate] = useState<DateType>(null);
  const [toDate, setToDate] = useState<DateType>(null);
  const { t } = useTranslation('translation', { keyPrefix: 'AdminDashboardTable' });

  useEffect(() => {
    getPartners();
  }, []);

  const headerCells: HeadCell[] = [
    {
      id: 'partner',
      numeric: false,
      label: t('headerCells.partner'),
      sortable: false,
    },
    {
      id: 'merchant',
      numeric: false,
      label: t('headerCells.merchant'),
      sortable: false,
    },
    {
      id: 'ticker',
      numeric: false,
      label: t('headerCells.ticker'),
      sortable: false,
    },
    {
      id: 'amount',
      numeric: true,
      label: t('headerCells.amount'),
      sortable: false,
    },
    {
      id: 'totalActivated',
      numeric: false,
      label: t('headerCells.totalActivated'),
      sortable: false,
    },
    {
      id: 'activatedAt',
      numeric: false,
      label: t('headerCells.activatedAt'),
      sortable: false,
    },
  ];

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isInTimePeriod = (itemDate: string) => {
    if (!fromDate || !toDate) {
      return true;
    }
    return itemDate >= fromDate.toISOString() && itemDate <= toDate.add(1, 'day').toISOString();
  };

  const codes = partners.map(({ email, activatedCodes, merchants, createdBy }) => {
    const activatedByMap: ActivatedByMapType = new Map();
    const totalData: TotalDataType = new Map();
    let totalActivatedCouter = 0;

    activatedByMap.set(createdBy, new Map());

    const calculate = (codesArray: Code[]) => {
      codesArray.forEach(code => {
        const codesMap = activatedByMap.get(code.activatedBy) ?? new Map();
        const ticker = code.ticker ?? 'NA';
        const tickerName = `${ticker}_${Number(code.amount)}`;
        if (codesMap && isInTimePeriod(code.activatedAt)) {
          const tickerValue = codesMap.get(tickerName);
          codesMap.set(tickerName, tickerValue ? [...tickerValue, code] : [code]);
          totalActivatedCouter++;

          const totalTickerData = totalData.get(ticker);
          totalData.set(ticker, totalTickerData ? totalTickerData + Number(code.amount) : Number(code.amount));
        }

        if (codesMap.size) {
          activatedByMap.set(code.activatedBy, codesMap);
        }
      });
    };

    calculate(activatedCodes);
    merchants.forEach(({ email, activatedCodes }) => {
      activatedByMap.set(email, new Map());
      calculate(activatedCodes);
    });

    return {
      email,
      total: totalActivatedCouter,
      activatedBy: activatedByMap,
      totalData,
    };
  });

  const clearFilters = () => {
    setFromDate(null);
    setToDate(null);
    setFilterEmail('');
  };

  const codesToRender = codes
    .filter(({ email, activatedBy }) => {
      const isInTimeRande = !!fromDate && !!toDate ? !!activatedBy.size : true;
      return email.includes(filterEmail) && isInTimeRande;
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <DashboardFilter
        email={filterEmail}
        fromDate={fromDate}
        toDate={toDate}
        handleEmailChange={setFilterEmail}
        handleFromDateChange={setFromDate}
        handleToDateChange={setToDate}
        onClearFilters={clearFilters}
        emailLabel={t('filterEmailLabel') as string}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <SimpleTableHead headCells={headerCells} padding />
          <TableBody>
            {codesToRender.map(({ email, activatedBy, total, totalData }) => {
              const component = Array.from(activatedBy).map(([email, tickerAmountCodes]) => (
                <Fragment key={email + '_activatedAt'}>
                  <ExpandableTableRow
                    isNested
                    disableExpand={!tickerAmountCodes.size}
                    expandComponent={Array.from(tickerAmountCodes).map(([tickerAmount, activatedCodes]) => (
                      <ExpandableTableRow
                        isNested
                        key={tickerAmount}
                        disableExpand={false}
                        expandComponent={activatedCodes.map(({ activatedAt }) => (
                          <TableRow key={activatedAt}>
                            <TableCell colSpan={5} />
                            <TableCell align="right">1</TableCell>
                            <TableCell align="right">{activatedAt}</TableCell>
                          </TableRow>
                        ))}
                      >
                        <TableCell />
                        <TableCell align="right">{tickerAmount.split('_')[0]}</TableCell>
                        <TableCell align="right">{tickerAmount.split('_')[1]}</TableCell>
                        <TableCell align="right">{activatedCodes.length}</TableCell>
                        <TableCell />
                      </ExpandableTableRow>
                    ))}
                  >
                    <TableCell align="right">{email}</TableCell>
                    <TableCell align="right" colSpan={3}>
                      {Array.from(tickerAmountCodes.values()).reduce((acc, array) => acc + array.length, 0)}
                    </TableCell>
                    <TableCell></TableCell>
                  </ExpandableTableRow>
                </Fragment>
              ));

              const partnerRow = (
                <>
                  {component}
                  <TotalTableSection sx={{ borderWidth: '3px' }} total={totalData} />
                </>
              );
              return (
                <ExpandableTableRow
                  sx={{ borderWidth: '3px' }}
                  key={email}
                  expandComponent={partnerRow}
                  disableExpand={false}
                >
                  <TableCell sx={{ borderWidth: '3px' }} align="left">
                    {email}
                  </TableCell>
                  <TableCell sx={{ borderWidth: '3px' }} align="right" colSpan={4}>
                    {total}
                  </TableCell>
                  <TableCell sx={{ borderWidth: '3px' }} align="right"></TableCell>
                </ExpandableTableRow>
              );
            })}
            <TotalTableSection total={getTotalSectionData(codesToRender)} />
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={partners.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
