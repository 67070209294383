import { FC } from 'react';
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TwoFAVerifyForm } from './TwoFAVerifyForm';
import { TwoFAStatusViewProps } from './types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(() => ({
  statusBar: {
    margin: '0 0 20px',
  },
}));

export const TwoFAStatusView: FC<TwoFAStatusViewProps> = ({
  verificationError,
  onTokenChange,
  handleDisable2FA,
  token,
  isVerificationLoading,
  disabled,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'TwoFactorAuth' });
  const {
    classes: { statusBar },
  } = useStyles();

  return (
    <>
      <div className={statusBar}>
        {t('statusLabel')}:{' '}
        <Chip
          label={t('enabledStatusText')}
          sx={{
            marginLeft: '10px',
          }}
          color="success"
          size="medium"
        />
      </div>
      <TwoFAVerifyForm
        verificationError={verificationError}
        onTokenChange={onTokenChange}
        handleProcess2FA={handleDisable2FA}
        token={token}
        isVerificationLoading={isVerificationLoading}
        buttonText={t('buttons.disable')}
        disabled={disabled}
      />
    </>
  );
};
