import { TableCell, TableCellProps, TableRow } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface TotalTableSectionProps {
  total: Map<string, number>;
  cellOffset?: number;
}

export const TotalTableSection: FC<TotalTableSectionProps & TableCellProps> = ({ total, cellOffset = 5, ...rest }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'TotalTableSection' });

  return (
    <>
      {!!total.size && (
        <TableRow>
          <TableCell rowSpan={total.size + 1} colSpan={cellOffset} {...rest} />
          <TableCell align="left" colSpan={2}>
            {t('total')}
          </TableCell>
        </TableRow>
      )}
      {Array.from(total)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([ticker, value], i) => (
          <TableRow key={ticker}>
            <TableCell align="left" {...(total.size === i + 1 && { ...rest })}>
              {ticker}
            </TableCell>
            <TableCell align="right" {...(total.size === i + 1 && { ...rest })}>
              {value}
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};
