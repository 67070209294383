import { FC, useContext, useState } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Button, InlineNotification, PasswordField } from '../../components';
import { AuthContext, RoutesPath } from '../../utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(() => ({
  textField: {
    marginBottom: 20,
  },
  forgotPasswordBlock: {
    textAlign: 'center',
    padding: '20px 0',
  },
}));

interface LoginFormProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

export const LoginForm: FC<LoginFormProps> = ({ email, setEmail }) => {
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {
    classes: { textField, forgotPasswordBlock },
  } = useStyles();
  const { signin, authError, setAuthError } = useContext(AuthContext);
  const { t } = useTranslation('translation', { keyPrefix: 'LoginForm' });

  const handleLogin = async () => {
    setIsLoading(true);
    await signin?.(email, password);
    setIsLoading(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    authError.length && setAuthError?.([]);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    authError.length && setAuthError?.([]);
  };

  const handleLoginOnKeypress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter') {
      handleLogin();
    }
  };

  return (
    <>
      <TextField
        className={textField}
        type="email"
        value={email}
        label={t('fields.email')}
        variant="outlined"
        onChange={handleEmailChange}
        onKeyDown={handleLoginOnKeypress}
        fullWidth
      />
      <PasswordField
        label={t('fields.password')}
        className={textField}
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={handleLoginOnKeypress}
        fullWidth
      />
      <InlineNotification open={!!authError.length} list={authError} severity="error" />
      <Button fullWidth onClick={handleLogin} isLoading={isLoading}>
        {t('buttons.login')}
      </Button>
      <div className={forgotPasswordBlock}>
        <Link to={`/${RoutesPath.PASSWORD_FORGOT}`}>{t('buttons.forgotPassword')}</Link>
      </div>
    </>
  );
};
