import { createContext, FC, PropsWithChildren, useState, useEffect } from 'react';
import { t } from 'i18next';
import { fetchData } from './api';
import { Role } from './types';
import { Code } from './AccountContext';

export interface IUser {
  id: string;
  email: string;
  hasWhitebitCredentials: boolean;
  twoFactorEnabled: boolean;
  two_factor_passed: boolean;
  role: Role;
  disabled: boolean;
  activatedCodes: Code[];
  createdBy: string;
}

export type User = IUser | null;

interface IAuthContext {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  isLoading: boolean;
  authError: string[];
  show2FAForm: boolean;
  signin: (email: string, password: string) => void;
  signout: () => void;
  setAuthError: React.Dispatch<React.SetStateAction<string[]>>;
  fetchUser: () => void;
  enable2FA: (token: string) => void;
  disable2FA: (token: string) => void;
  verify2FAToken: (token: string, email: string) => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [authError, setAuthError] = useState<string[]>([]);
  const [user, setUser] = useState<User>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [show2FAForm, setShow2FAForm] = useState<boolean>(false);

  const fetchUser = async () => {
    try {
      const user = await fetchData('auth/session');

      if (user.twoFactorEnabled && !user.two_factor_passed) {
        return setUser(null);
      }

      if (user) {
        setUser(user);
      }
    } catch (error) {
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const signin = async (email: string, password: string) => {
    try {
      setAuthError([]);
      const user = await fetchData('auth/signin', {
        method: 'POST',
        body: JSON.stringify({ email, password }),
      });

      if (user.twoFactorEnabled && !user.two_factor_passed) {
        return setShow2FAForm(true);
      }

      if (user) {
        setUser(user);
      }
    } catch (error) {
      if (error.statusCode === 429) {
        setAuthError([t('messages.rateLimit', { minutes: error.message })]);
        return;
      }

      const messagesArr = Array.isArray(error.message) ? error.message : [error.message];
      setAuthError(error.errors ?? messagesArr);
    }
  };

  const signout = async () => {
    await fetchData('auth/signout', {
      method: 'POST',
    });

    setUser(null);
  };

  const verify2FAToken = async (token: string, email: string) => {
    try {
      const user = await fetchData('auth/2fa', {
        method: 'POST',
        body: JSON.stringify({ token, email }),
      });

      if (user) {
        setShow2FAForm(false);
        setUser(user);
      }
    } catch (error) {
      throw error;
    }
  };

  const enable2FA = async (token: string) => {
    try {
      const user = await fetchData('auth/2fa', {
        method: 'PATCH',
        body: JSON.stringify({ token }),
      });

      if (user) {
        setUser(user);
      }
    } catch (error) {
      throw error;
    }
  };

  const disable2FA = async (token: string) => {
    try {
      const user = await fetchData('auth/2fa', {
        method: 'DELETE',
        body: JSON.stringify({ token }),
      });

      if (user) {
        setUser(user);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        authError,
        show2FAForm,
        signout,
        signin,
        setUser,
        setAuthError,
        fetchUser,
        enable2FA,
        disable2FA,
        verify2FAToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
