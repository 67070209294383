import { FC, useContext, useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { Button } from '../Button';
import { AuthContext, CreatePartnerData } from '../../utils';
import { PaperWrapper } from '../PaperWrapper';
import { InlineNotification, PasswordField } from '..';
import { useTranslation } from 'react-i18next';

interface CreateUserEntityProps {
  createButtonText: string;
  createEntity: (data: CreatePartnerData) => Promise<void>;
  errors: string[];
  isLoading: boolean;
}

export const CreateUserEntity: FC<CreateUserEntityProps> = ({ createButtonText, createEntity, errors, isLoading }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [readOnly, setReadOnly] = useState(true);
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('translation', { keyPrefix: 'CreateUserEntity' });

  useEffect(() => {
    const timer = setTimeout(() => {
      setReadOnly(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handCreate = async () => {
    await createEntity({ email, password });
    setEmail('');
    setPassword('');
  };
  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = e => setEmail(e.target.value);
  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = e => setPassword(e.target.value);

  return (
    <PaperWrapper>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs={12} md={4}>
          <TextField
            value={email}
            fullWidth
            type="email"
            label={t('fields.email')}
            variant="outlined"
            onChange={handleEmailChange}
            disabled={readOnly}
            inputProps={{
              readOnly,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PasswordField
            label={t('fields.password')}
            value={password}
            fullWidth
            onChange={handlePasswordChange}
            disabled={readOnly}
            inputProps={{
              readOnly,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button onClick={handCreate} fullWidth isLoading={isLoading} disabled={user?.disabled || isLoading}>
            {createButtonText}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <InlineNotification open={!!errors.length} ttl={8000} list={errors} severity="error" />
        </Grid>
      </Grid>
    </PaperWrapper>
  );
};
