import { PropsWithChildren, useState, FC, ReactNode } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { IconButton, TableCell, TableRow, TableCellProps } from '@mui/material';

interface ExpandableTableRowProps extends PropsWithChildren {
  expandComponent: ReactNode;
  isNested?: boolean;
  disableExpand: boolean;
}

export const ExpandableTableRow: FC<ExpandableTableRowProps & TableCellProps> = ({
  children,
  expandComponent,
  isNested,
  disableExpand,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <TableRow>
        {isNested && <TableCell padding="checkbox" {...rest}></TableCell>}
        <TableCell padding="checkbox" {...rest}>
          <IconButton disabled={disableExpand} onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && expandComponent}
    </>
  );
};
