import {
  Button as MuiButton,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
} from '@mui/material';
import { ChangeEventHandler, FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QrScanner from 'qr-scanner';
import { makeStyles } from 'tss-react/mui';
import { AuthContext, Code, PartnerContext, Role, SelectedCode, fetchData, useNotification } from '../../utils';
import { ProgressBar } from '../ProgressBar';
import { CodesTable } from '../CodesTable';
import { Button } from '../Button';
import { QrModal } from './QrModal';
import { PaperWrapper } from '../PaperWrapper';
import { InlineNotification } from '../InlineNotification';
import { useTranslation } from 'react-i18next';
const Papa = require('papaparse');

interface CSVCodes {
  codes: Code[];
  fileName: string;
}

interface CSVCodeActivationSectionProps {
  onCodeActivate?: () => void;
}

const parseFile = (file: File) => {
  return new Promise<CSVCodes>((complete, error) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader: (header: string) => header.toLowerCase(),
      complete: (results: any, file: any) => {
        complete({ codes: results.data as Code[], fileName: file.name });
      },
      error,
    });
  });
};

const useStyles = makeStyles()(theme => ({
  buttons: {
    marginBottom: '20px',
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '20px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '10px',
    },
  },
}));

export const CodeActivationSection: FC<CSVCodeActivationSectionProps> = ({ onCodeActivate }) => {
  const { accountId } = useParams();
  const { user, fetchUser } = useContext(AuthContext);
  const { partners } = useContext(PartnerContext);
  const [csvData, setCsvData] = useState<CSVCodes[]>([]);
  const [isActivationInProcess, setIsActivationInProcess] = useState(false);
  const [activatedCodesCounter, setActivatedCodesCounter] = useState<number>(0);
  const [failedCodesCounter, setFailedCodesCounter] = useState<number>(0);
  const [codesToActivate, setCodesToActivate] = useState<number>(0);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [singleCode, setSingleCode] = useState('');
  const [partnersSelectValue, setPartnersSelectValue] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState<SelectedCode[]>([]);
  const { openNotification } = useNotification();
  const { t } = useTranslation('translation', { keyPrefix: 'CodeActivationSection' });

  useEffect(() => {
    if (selectedCodes.length) {
      setSingleCode('');
    }
  }, [selectedCodes]);

  const {
    classes: { buttons, button },
  } = useStyles();

  const csvCodesHeadCells = [
    {
      id: 'ticker',
      numeric: false,
      label: t('csvCodesHeadCells.ticker'),
    },
    {
      id: 'amount',
      numeric: true,
      label: t('csvCodesHeadCells.amount'),
    },
    {
      id: 'code',
      numeric: false,
      label: t('csvCodesHeadCells.code'),
    },
    {
      id: 'password',
      numeric: false,
      label: t('csvCodesHeadCells.password'),
    },
  ];

  const onFileUpload: React.ChangeEventHandler<HTMLInputElement> = async event => {
    setActivatedCodesCounter(0);
    setFailedCodesCounter(0);
    setCodesToActivate(0);
    if (event.target.files) {
      const promises = Array.from(event.target.files).map((file: any) => parseFile(file));
      const results = await Promise.allSettled(promises);
      const csvCodes: CSVCodes[] = [];
      results.forEach(result => {
        if (result.status === 'fulfilled') {
          csvCodes.push({ codes: result.value.codes, fileName: result.value.fileName });
        }
      });
      setCsvData(csvCodes);
      setSingleCode('');
    }
  };

  const activateWithDelayByStep = (url: string, codes: SelectedCode[]) => {
    setActivatedCodesCounter(0);
    setFailedCodesCounter(0);
    setCodesToActivate(0);
    let counter = 0;

    const activate = (start: number) => {
      let curInd = start;
      setCodesToActivate(codes.length);

      fetchData(url, {
        method: 'POST',
        body: JSON.stringify({ ...codes[curInd], ...(partnersSelectValue && { partnerId: partnersSelectValue }) }),
      })
        .then(() => {
          setActivatedCodesCounter(prevVal => (prevVal += 1));
          if (codes.length === 1) {
            openNotification(t('notifications.codeActivated'));
            setShowSuccessMessage(true);
          }
        })
        .catch(() => {
          setShowErrorMessage(true);
          setFailedCodesCounter(prevVal => (prevVal += 1));
          if (codes.length === 1) {
            openNotification(t('notifications.activationFailed'), 'error');
          }
        })
        .finally(() => {
          if (counter < codes.length - 1) {
            counter += 1;
            setTimeout(() => {
              return activate(counter);
            }, 100);
          } else {
            setIsActivationInProcess(false);
            setSingleCode('');
            fetchUser();
            onCodeActivate?.();
          }
        });
    };

    activate(counter);
  };

  const activateCodes = () => {
    setIsActivationInProcess(true);
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
    const codes = [...selectedCodes, ...(!!singleCode ? [{ code: singleCode }] : [])];
    activateWithDelayByStep(
      `users/${user?.id}/codes`,
      codes.map(({ code }) => ({ code })),
    );
  };

  const applyCodes = () => {
    setIsActivationInProcess(true);
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
    activateWithDelayByStep(`accounts/${accountId}/codes`, selectedCodes);
  };

  const handleCodeChange: ChangeEventHandler<HTMLInputElement> = event => {
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
    setSingleCode(event.target.value);
  };

  const handleSetPartnersSelectValue = (event: SelectChangeEvent) =>
    setPartnersSelectValue(event.target.value as string);

  const handleModalOpen = () => {
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
    setIsModalOpened(true);
  };

  const handleCloseModal = () => setIsModalOpened(false);
  const handleQrScan = (result: QrScanner.ScanResult) => setSingleCode(result.data);

  const isButtonDisabled = user?.disabled || !selectedCodes.length || isActivationInProcess;

  return (
    <PaperWrapper>
      <div className={buttons}>
        <Button onClick={handleModalOpen} className={button} disabled={user?.disabled}>
          {t('buttons.scan')}
        </Button>
        {user?.role === Role.Admin && (
          <MuiButton variant="contained" component="label" size="large" className={button}>
            {t('buttons.upload')}
            <input hidden accept=".csv, text/csv" multiple={false} type="file" onChange={onFileUpload} />
          </MuiButton>
        )}
      </div>
      <Grid
        sx={{ marginBottom: '20px' }}
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} md={5}>
          <TextField
            value={singleCode}
            fullWidth
            type="text"
            label={t('fields.code')}
            variant="outlined"
            onChange={handleCodeChange}
            disabled={!!selectedCodes.length}
          />
        </Grid>
        {user?.role === Role.Admin && !accountId && (
          <Grid item xs={12} md={5}>
            <FormControl fullWidth>
              <InputLabel id="partners-select">{t('fields.partner')}</InputLabel>
              <Select
                fullWidth
                labelId="partners-select"
                value={partnersSelectValue}
                label={t('fields.partner')}
                variant="outlined"
                onChange={handleSetPartnersSelectValue}
              >
                <MenuItem value="">
                  <em>{t('fields.partnerNone')}</em>
                </MenuItem>
                {partners.map(({ email, id }) => (
                  <MenuItem key={email} value={id}>
                    {email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <div className={buttons}>
        <Button
          className={button}
          disabled={singleCode ? false : isButtonDisabled}
          onClick={activateCodes}
          isLoading={isActivationInProcess}
        >
          {t('buttons.activate')}
        </Button>
        {!!accountId && (
          <Button disabled={isButtonDisabled} onClick={applyCodes} className={button} isLoading={isActivationInProcess}>
            {t('buttons.apply')}
          </Button>
        )}
      </div>
      <InlineNotification
        ttl={5000}
        open={showErrorMessage || showSuccessMessage}
        severity={showErrorMessage ? 'error' : 'success'}
        onClose={() => (showErrorMessage ? setShowErrorMessage(false) : setShowSuccessMessage(false))}
      >
        {showErrorMessage ? t('notifications.activationFailed') : t('notifications.codeActivated')}
      </InlineNotification>
      {csvData.map((data, i) => (
        <div key={i}>
          <h2>{`Codes from "${data.fileName}"`}</h2>
          {codesToActivate > 0 && (
            <>
              <ProgressBar
                value={((activatedCodesCounter + failedCodesCounter) / codesToActivate) * 100}
                sx={{ height: '10px', borderRadius: '5px' }}
              />
              <div style={{ marginBottom: '40px', color: 'green' }}>
                <span style={{ marginRight: '20px' }}>
                  {`${t('info.activated')}:`} {activatedCodesCounter} of {codesToActivate}
                </span>
                {failedCodesCounter > 0 && (
                  <span style={{ color: 'red' }}>
                    {`${t('info.failed')}:`} {failedCodesCounter} of {codesToActivate}
                  </span>
                )}
              </div>
            </>
          )}
          <CodesTable
            codes={data.codes}
            headCells={csvCodesHeadCells}
            selectedCodes={selectedCodes}
            setSelectedCodes={setSelectedCodes}
          />
        </div>
      ))}
      <QrModal open={isModalOpened} onClose={handleCloseModal} onScan={handleQrScan} />
    </PaperWrapper>
  );
};
