import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, FormControl, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { AuthContext, RoutesPath } from '../../utils';
import { AccountMenu } from './AccountMenu';
import { NavBar } from '../NavBar';
import { MobileDrawer } from '../MobileDrawer';
import { useTranslation } from 'react-i18next';
import { fallbackLng, languages } from '../../i18n';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      padding: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      background: '#000',
      color: '#fff',
    },
  };
});

export const Header: FC = () => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const {
    classes: { root },
  } = useStyles();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Header' });

  return (
    <div className={root}>
      {user ? (
        <>
          <span>{user.email}</span>
          <FormControl>
            <Select
              value={languages.find(lang => i18n.language.includes(lang)) ?? fallbackLng}
              sx={{ color: 'white', marginLeft: '16px', svg: { color: 'white' } }}
              onChange={e => i18n.changeLanguage(e.target.value)}
            >
              {languages.map(lng => (
                <MenuItem key={lng} value={lng}>
                  {lng.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {matches && <AccountMenu />}
          <MobileDrawer openIcon={<MenuIcon />} closeIcon={<MenuOpenIcon />} drawerProps={{ anchor: 'left' }}>
            <NavBar isMobile={!matches} />
          </MobileDrawer>
        </>
      ) : (
        <Button component={Link} to={`/${RoutesPath.LOGIN}`} size="small">
          {t('buttons.login')}
        </Button>
      )}
    </div>
  );
};
