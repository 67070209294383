import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';
import { PageNotigication } from '../components/PageNotigication';
import { AlertColor } from '@mui/material';

interface INotificationContext {
  openNotification: (body: string, severity?: AlertColor) => void;
}

export const NotificationContext = createContext<INotificationContext>({} as INotificationContext);

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [body, setBody] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  useEffect(() => {
    if (!isOpened) {
      setBody('');
      setSeverity('success');
    }
  }, [isOpened]);

  const openNotification = (body: string, severity?: AlertColor) => {
    setBody(body);
    if (severity) {
      setSeverity(severity);
    }
    setIsOpened(true);
  };

  const closeNotification = () => {
    setIsOpened(false);
  };

  return (
    <NotificationContext.Provider value={{ openNotification }}>
      {children}
      <PageNotigication open={isOpened} body={body} onClose={closeNotification} severity={severity} />
    </NotificationContext.Provider>
  );
};
