import { FC, useContext, useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { Loader, PaperWrapper } from '../../../components';
import { AuthContext, fetchData, useNotification } from '../../../utils';
import { TwoFAData } from './types';
import { TwoFAEnableView } from './TwoFAEnableView';
import { TwoFAStatusView } from './TwoFAStatusView';
import { useTranslation } from 'react-i18next';

export const TwoFactorAuth: FC = () => {
  const { enable2FA, disable2FA, user } = useContext(AuthContext);
  const [twoFAData, setTwoFAData] = useState<TwoFAData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVerificationLoading, setVerificationIsLoading] = useState<boolean>(false);
  const [verificationError, setVerificationError] = useState<string>('');
  const [twoFADataError, setTwoFADataError] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const { openNotification } = useNotification();
  const { t } = useTranslation('translation', { keyPrefix: 'TwoFactorAuth' });

  useEffect(() => {
    const generate = async () => {
      try {
        setTwoFADataError('');
        setIsLoading(true);
        const response = await fetchData('auth/2fa');
        setTwoFAData(response);
      } catch (error) {
        setTwoFADataError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (!user?.twoFactorEnabled) {
      generate();
    }
  }, [user?.twoFactorEnabled]);

  const onTokenChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setVerificationError('');
    setToken(event.target.value);
  };

  const handle2FA = async (enable?: boolean) => {
    const isTokenValid = /^\d{6}$/gm.test(token);
    if (!isTokenValid) {
      return setVerificationError(t('isTokenValidMessage') as string);
    }

    try {
      setVerificationIsLoading(true);
      enable ? await enable2FA?.(token) : await disable2FA?.(token);
      openNotification(enable ? t('enabledMessage') : t('disabledMessage'));
    } catch (error) {
      setVerificationError(error.message);
    } finally {
      setVerificationIsLoading(false);
      setToken('');
    }
  };

  const handleEnable2FA = () => handle2FA(true);

  const handleDisable2FA = () => handle2FA(false);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PaperWrapper>
      <h2>{t('title')}</h2>
      {twoFADataError && (
        <Alert color="error" sx={{ marginBottom: '30px' }}>
          {twoFADataError}
        </Alert>
      )}
      {!user?.twoFactorEnabled ? (
        twoFAData && (
          <TwoFAEnableView
            twoFAData={twoFAData}
            verificationError={verificationError}
            onTokenChange={onTokenChange}
            handleEnable2FA={handleEnable2FA}
            token={token}
            isVerificationLoading={isVerificationLoading}
            disabled={user?.disabled as boolean}
          />
        )
      ) : (
        <TwoFAStatusView
          verificationError={verificationError}
          onTokenChange={onTokenChange}
          handleDisable2FA={handleDisable2FA}
          token={token}
          isVerificationLoading={isVerificationLoading}
          disabled={user?.disabled}
        />
      )}
    </PaperWrapper>
  );
};
