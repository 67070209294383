import { Button, Chip, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { MobileDrawer } from '../../components';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { DateType } from './types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  filterBar: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },

    marginBottom: 20,
  },
  filterLabel: {
    [theme.breakpoints.up('md')]: {
      marginRight: '15px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
      display: 'block',
    },
  },
  filterTextFiled: {
    [theme.breakpoints.up('md')]: {
      marginRight: '15px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
      width: '100%',
    },
  },
  drawer: {
    width: '250px',
    padding: '10px',
  },
  mobileFilters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

interface DashboardFilterComponentProps {
  email?: string;
  emailLabel?: string;
  handleEmailChange?: (value: string) => void;
  fromDate: DateType;
  toDate: DateType;
  handleFromDateChange: (value: DateType) => void;
  handleToDateChange: (value: DateType) => void;
  onClearFilters: () => void;
}

const DashboardFilterComponent: FC<DashboardFilterComponentProps> = ({
  email,
  fromDate,
  toDate,
  handleEmailChange,
  handleFromDateChange,
  handleToDateChange,
  onClearFilters,
  emailLabel,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const {
    classes: { filterBar, filterLabel, filterTextFiled },
  } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'DashboardFilter' });

  return (
    <div className={filterBar}>
      {handleEmailChange && (
        <>
          <span className={filterLabel}>{emailLabel}</span>
          <TextField
            className={filterTextFiled}
            type="email"
            value={email}
            label={t('fields.email')}
            variant="outlined"
            onChange={e => handleEmailChange(e.target.value)}
          />
        </>
      )}
      <span className={filterLabel}>{t('activationDateLabel')}</span>
      <DatePicker
        label={t('fields.from')}
        format="DD/MM/YYYY"
        className={filterTextFiled}
        value={fromDate}
        onChange={newValue => handleFromDateChange(newValue as DateType)}
        slotProps={{
          textField: {
            // @ts-ignore
            readOnly: true,
          },
        }}
      />
      <DatePicker
        label={t('fields.to')}
        format="DD/MM/YYYY"
        className={filterTextFiled}
        value={toDate}
        onChange={newValue => handleToDateChange(newValue as DateType)}
        slotProps={{
          textField: {
            // @ts-ignore
            readOnly: true,
          },
        }}
      />
      <Button
        disabled={!email && !fromDate && !toDate}
        variant={matches ? 'text' : 'contained'}
        onClick={onClearFilters}
      >
        {t('buttons.clear')}
      </Button>
    </div>
  );
};

export const DashboardFilter: FC<DashboardFilterComponentProps> = ({
  email,
  fromDate,
  toDate,
  handleEmailChange,
  handleFromDateChange,
  handleToDateChange,
  onClearFilters,
  emailLabel,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const {
    classes: { mobileFilters, drawer },
  } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'DashboardFilter' });

  const showChips = email || (fromDate && toDate);

  const handleClearDates = () => {
    handleToDateChange(null);
    handleToDateChange(null);
  };

  return matches ? (
    <>
      <h4>{t('title.desktop')}</h4>
      <DashboardFilterComponent
        email={email}
        fromDate={fromDate}
        toDate={toDate}
        handleEmailChange={handleEmailChange}
        handleFromDateChange={handleFromDateChange}
        handleToDateChange={handleToDateChange}
        onClearFilters={onClearFilters}
        emailLabel={emailLabel}
      />
    </>
  ) : (
    <>
      <div className={mobileFilters}>
        <h4>{t('title.mobile')}</h4>
        <MobileDrawer openIcon={<FilterListIcon />} closeIcon={<FilterListOffIcon />} drawerProps={{ anchor: 'right' }}>
          <div className={drawer}>
            <DashboardFilterComponent
              email={email}
              fromDate={fromDate}
              toDate={toDate}
              handleEmailChange={handleEmailChange}
              handleFromDateChange={handleFromDateChange}
              handleToDateChange={handleToDateChange}
              onClearFilters={onClearFilters}
              emailLabel={emailLabel}
            />
          </div>
        </MobileDrawer>
      </div>
      {showChips && (
        <Stack
          direction="row"
          sx={{ margin: '0 0 20px 15px', alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap' }}
        >
          {email && handleEmailChange && (
            <Chip
              label={email}
              color="primary"
              sx={{ margin: '5px' }}
              size="small"
              onDelete={() => handleEmailChange('')}
            />
          )}
          {fromDate && toDate && (
            <>
              <Chip
                label={fromDate?.format('DD/MM/YYYY')}
                sx={{ margin: '5px' }}
                color="primary"
                size="small"
                onDelete={handleClearDates}
              />
              -
              <Chip
                label={toDate?.format('DD/MM/YYYY')}
                sx={{ margin: '5px' }}
                color="primary"
                size="small"
                onDelete={handleClearDates}
              />
            </>
          )}
          <Button variant="text" sx={{ margin: '5px' }} size="small" onClick={onClearFilters}>
            {t('buttons.clear')}
          </Button>
        </Stack>
      )}
    </>
  );
};
