import { FC } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AccountProvider, AuthProvider, PartnerProvider, NotificationProvider, ModalProvider } from './utils';
import { Router } from './Router';
import './fonts';

export const App: FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ModalProvider>
        <NotificationProvider>
          <PartnerProvider>
            <AccountProvider>
              <AuthProvider>
                <Router />
              </AuthProvider>
            </AccountProvider>
          </PartnerProvider>
        </NotificationProvider>
      </ModalProvider>
    </LocalizationProvider>
  );
};
