import { FC, useContext, useState } from 'react';
import { AuthContext } from '../../utils';
import { TwoFATokenForm } from './TwoFATokenForm';
import { LoginForm } from './LoginForm';
import { CenteredPageTemplate } from '../PageTemplate';

export const LoginPage: FC = () => {
  const [email, setEmail] = useState('');
  const { show2FAForm } = useContext(AuthContext);

  return (
    <CenteredPageTemplate>
      {show2FAForm ? <TwoFATokenForm email={email} /> : <LoginForm email={email} setEmail={setEmail} />}
    </CenteredPageTemplate>
  );
};
