import { useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AdminRoute, Loader, PartnerRoute, ProtectedRoute } from './components';
import {
  DashboardPage,
  LoginPage,
  PageNotFound,
  AccountsPage,
  HomePage,
  SettingsPage,
  AccountPage,
  ResetPasswordPage,
  ForgotPasswordPage,
  PartnersPage,
  MerchantsPage,
} from './pages';
import { AuthContext, RoutesPath } from './utils';

export const Router = () => {
  const { user, isLoading } = useContext(AuthContext);

  return isLoading ? (
    <Loader type="page" />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path={RoutesPath.LOGIN} element={user ? <Navigate to={RoutesPath.HOME} replace /> : <LoginPage />} />
        <Route
          path={RoutesPath.HOME}
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutesPath.ACCOUNTS}
          element={
            <ProtectedRoute>
              <AdminRoute>
                <AccountsPage />
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${RoutesPath.ACCOUNTS}/:accountId`}
          element={
            <ProtectedRoute>
              <AdminRoute>
                <AccountPage />
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutesPath.PARTNERS}
          element={
            <ProtectedRoute>
              <AdminRoute>
                <PartnersPage />
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutesPath.MERCHANTS}
          element={
            <ProtectedRoute>
              <PartnerRoute>
                <MerchantsPage />
              </PartnerRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutesPath.DASHBOARD}
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutesPath.SETTINGS}
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
        <Route path={RoutesPath.PASSWORD_RESET} element={<ResetPasswordPage />} />
        <Route path={RoutesPath.PASSWORD_FORGOT} element={<ForgotPasswordPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
