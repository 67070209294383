import { FC, useContext } from 'react';
import { ListItemIcon, ListItemText, MenuItem, MenuList, Paper } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { Dashboard, Group, Handshake } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AuthContext, Role, RoutesPath } from '../../utils';
import { AccountMenuItems } from '../Header/AccountMenuItems';

interface NavBarProps {
  isMobile?: boolean;
}

export const NavBar: FC<NavBarProps> = ({ isMobile }) => {
  const { pathname } = useLocation();
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('translation', { keyPrefix: 'nav' });

  const navData = [
    {
      to: `/${RoutesPath.DASHBOARD}`,
      text: t('dashboard'),
      Icon: Dashboard,
      roles: [Role.Admin, Role.Partner, Role.Merchant],
    },
    {
      to: `/${RoutesPath.ACCOUNTS}`,
      text: t('accounts'),
      Icon: Group,
      roles: [Role.Admin],
    },
    {
      to: `/${RoutesPath.PARTNERS}`,
      text: t('partners'),
      Icon: Handshake,
      roles: [Role.Admin],
    },
    {
      to: `/${RoutesPath.MERCHANTS}`,
      text: t('merchants'),
      Icon: Handshake,
      roles: [Role.Partner],
    },
  ];

  return (
    <Paper elevation={3}>
      <MenuList sx={{ padding: 0 }}>
        {navData
          .filter(({ roles }) => roles?.includes(user?.role as Role))
          .map(({ to, text, Icon }, i) => (
            <MenuItem
              key={text}
              divider
              component={NavLink}
              to={to}
              sx={theme => ({
                ...(pathname === to && { backgroundColor: theme.palette.grey[100] }),
                padding: '12px 16px',
              })}
            >
              <ListItemIcon>
                <Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{text}</ListItemText>
            </MenuItem>
          ))}
        {isMobile && <AccountMenuItems divider />}
      </MenuList>
    </Paper>
  );
};
