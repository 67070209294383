export enum Actions {
  setOldPass = 'setOldPass',
  setNewPass = 'setNewPass',
  setConfirmNewPass = 'setConfirmNewPass',
  setMatchPasswordError = 'setMatchPasswordError',
  setError = 'setError',
  resetErrors = 'resetErrors',
  setSuccessMessage = 'setSuccessMessage',
}

export interface State {
  oldPass: string;
  newPass: string;
  confirmNewPass: string;
  matchPasswordError: string;
  error: string;
  successMessage: string;
}

export interface Action {
  type: keyof typeof Actions;
  payload?: string | boolean;
}

export const reducer = (state: State, { type, payload }: Action): State => {
  switch (type) {
    case Actions.setOldPass:
      return {
        ...state,
        oldPass: payload as string,
      };
    case Actions.setNewPass:
      return {
        ...state,
        newPass: payload as string,
      };
    case Actions.setConfirmNewPass:
      return {
        ...state,
        confirmNewPass: payload as string,
      };
    case Actions.setMatchPasswordError:
      return {
        ...state,
        matchPasswordError: payload as string,
      };
    case Actions.setError:
      return {
        ...state,
        error: payload as string,
      };
    case Actions.resetErrors:
      return {
        ...state,
        error: '',
        matchPasswordError: '',
      };
    case Actions.setSuccessMessage:
      return {
        ...state,
        successMessage: payload as string,
      };

    default:
      return state;
  }
};

export const initialState: State = {
  oldPass: '',
  newPass: '',
  confirmNewPass: '',
  matchPasswordError: '',
  error: '',
  successMessage: '',
};
