import { FC, useContext } from 'react';
import { PageTemplate } from '../PageTemplate/PageTemplate';
import { CodeActivationSection, PaperWrapper } from '../../components';
import { AuthContext, PartnerContext, Role } from '../../utils';
import { MerchantDashboardTable } from './MerchantDashboardTable';
import { PartnerDashboardTable } from './PartnerDashboardTable';
import { AdminDashboardTable } from './AdminDashboardTable';
import { useTranslation } from 'react-i18next';

export const DashboardPage: FC = () => {
  const { user } = useContext(AuthContext);
  const { getPartners } = useContext(PartnerContext);
  const { t } = useTranslation('translation', { keyPrefix: 'pages.dashboard' });

  if (!user) return null;

  const dashboardTableMap = {
    [Role.Merchant]: <MerchantDashboardTable codes={user.activatedCodes} />,
    [Role.Partner]: <PartnerDashboardTable user={user} />,
    [Role.Admin]: <AdminDashboardTable />,
  };

  const onCodeActivate = () => {
    if (user.role === Role.Admin) {
      getPartners();
    }
  };

  return (
    <PageTemplate pageTitle={t('title') as string}>
      <CodeActivationSection onCodeActivate={onCodeActivate} />
      <PaperWrapper>{dashboardTableMap[user.role]}</PaperWrapper>
    </PageTemplate>
  );
};
