import { FC, useContext, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { Button } from '../Button';
import { AccountContext, useNotification } from '../../utils';
import { PaperWrapper } from '../PaperWrapper';
import { InlineNotification } from '../InlineNotification';
import { useTranslation } from 'react-i18next';

export const CreateAccount: FC = () => {
  const [email, setEmail] = useState('');
  const [securityKey, setSecurityKey] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState([]);
  const { createAccount } = useContext(AccountContext);
  const { openNotification } = useNotification();
  const { t } = useTranslation('translation', { keyPrefix: 'CreateAccount' });

  const handCreate = async () => {
    try {
      setIsLoading(true);
      await createAccount({ email, apiKey, securityKey });
      setEmail('');
      setSecurityKey('');
      setApiKey('');
      openNotification(t('createdAccountMessage'));
    } catch ({ message, errors }) {
      const messagesArr = Array.isArray(message) ? message : [message];
      setApiError(errors ?? messagesArr);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setApiError([]);
    setEmail(e.target.value);
  };
  const handleSecurityKeyChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setApiError([]);
    setSecurityKey(e.target.value);
  };
  const handleApiKeyChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setApiError([]);
    setApiKey(e.target.value);
  };

  return (
    <PaperWrapper>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs={12} md={3}>
          <TextField
            value={email}
            fullWidth
            type="email"
            label={t('fields.email')}
            variant="outlined"
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={securityKey}
            fullWidth
            label={t('fields.securityKey')}
            variant="outlined"
            onChange={handleSecurityKeyChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            value={apiKey}
            fullWidth
            label={t('fields.apiKey')}
            variant="outlined"
            onChange={handleApiKeyChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button onClick={handCreate} fullWidth disabled={isLoading} isLoading={isLoading}>
            {t('buttons.create')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <InlineNotification open={!!apiError.length} ttl={8000} list={apiError} severity="error" />
        </Grid>
      </Grid>
    </PaperWrapper>
  );
};
