import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ChangeEvent, FC, useState } from 'react';
import { Code } from '../../utils';
import { EnhancedTableHead, HeadCell, Order } from '../../components/CodesTable/EnhancedTableHead';
import { getComparator, stableSort } from '../../components';
import { TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DashboardFilter } from './DashboardFilter';
import { DateType } from './types';

interface DashboardTableProps {
  codes: Code[];
}

const bodyCells: Record<string, keyof Code>[] = [
  {
    id: 'ticker',
  },
  {
    id: 'amount',
  },
  {
    id: 'activatedAt',
  },
];

export const MerchantDashboardTable: FC<DashboardTableProps> = ({ codes }) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Code>('amount');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [fromDate, setFromDate] = useState<DateType>(null);
  const [toDate, setToDate] = useState<DateType>(null);
  const { t } = useTranslation('translation', { keyPrefix: 'MerchantDashboardTable' });

  if (!codes?.length) {
    return <p>{t('noActivatedCodes')}</p>;
  }

  const headerCells: HeadCell[] = [
    {
      id: 'ticker',
      numeric: false,
      label: t('headerCells.ticker'),
    },
    {
      id: 'amount',
      numeric: true,
      label: t('headerCells.amount'),
    },
    {
      id: 'activatedAt',
      numeric: false,
      label: t('headerCells.activatedAt'),
    },
  ];

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as keyof Code);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const clearFilters = () => {
    setFromDate(null);
    setToDate(null);
  };

  const isInTimePeriod = (itemDate: string) => {
    if (!fromDate || !toDate) {
      return true;
    }
    return itemDate >= fromDate.toISOString() && itemDate <= toDate.add(1, 'day').toISOString();
  };

  const codesToRender = codes.filter(({ activatedAt }) => isInTimePeriod(activatedAt));

  const total = codesToRender.reduce((acc, { ticker, amount }) => {
    const tickerAmount = Number(acc.get(ticker));

    if (!tickerAmount) {
      acc.set(ticker, amount);
    } else {
      acc.set(ticker, tickerAmount + Number(amount));
    }

    return acc;
  }, new Map());

  return (
    <>
      <DashboardFilter
        fromDate={fromDate}
        toDate={toDate}
        handleFromDateChange={setFromDate}
        handleToDateChange={setToDate}
        onClearFilters={clearFilters}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={codes.length}
            headCells={headerCells}
          />
          <TableBody>
            {stableSort(codesToRender, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(code => (
                <TableRow key={code.activatedAt} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {bodyCells.map(({ id }, i) => (
                    <TableCell key={id} align={i === 0 ? 'left' : 'right'}>
                      {code[id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            <TableRow>
              <TableCell rowSpan={total.size + 1} colSpan={2} />
              <TableCell align="left">{t('totalLabel')}</TableCell>
            </TableRow>
            {Array.from(total).map(([ticker, value]) => (
              <TableRow key={ticker}>
                <TableCell>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{ticker}</span> <span>{value}</span>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={codesToRender.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
