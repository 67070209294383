import { FC } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Button } from '../../../components';
import { TwoFAVerifyFormProps } from './types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(() => ({
  verifyBox: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

export const TwoFAVerifyForm: FC<TwoFAVerifyFormProps> = ({
  verificationError,
  onTokenChange,
  handleProcess2FA,
  token,
  isVerificationLoading,
  buttonText,
  disabled,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'TwoFactorAuth' });
  const {
    classes: { verifyBox },
  } = useStyles();

  return (
    <div className={verifyBox}>
      <TextField
        error={!!verificationError}
        onChange={onTokenChange}
        onKeyDown={event => {
          if (event.code === 'Enter') {
            handleProcess2FA();
          }
        }}
        value={token}
        sx={{ marginRight: '20px' }}
        label={t('fields.2faCode')}
        helperText={verificationError}
      />
      <Button onClick={handleProcess2FA} sx={{ height: '54px' }} isLoading={isVerificationLoading} disabled={disabled}>
        {buttonText}
      </Button>
    </div>
  );
};
