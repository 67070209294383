import { createContext, FC, PropsWithChildren, useState } from 'react';
import { fetchData } from './api';
import { Role } from './types';
import { IUser } from './AuthContext';

export interface CreateUserData {
  email: string;
  password: string;
  role: Role;
}

export type CreatePartnerData = Pick<CreateUserData, 'email' | 'password'>;

export interface Partner extends IUser {
  merchants: IUser[];
}

export interface IPartnerContext {
  partners: Partner[];
  merchants: IUser[];
  getPartners: () => Promise<void>;
  getMerchants: () => Promise<void>;
  createPartner: (data: CreatePartnerData) => Promise<void>;
  deletePartner: (id: string) => Promise<void>;
  deleteMerchant: (id: string) => Promise<void>;
  createMerchant: (data: CreatePartnerData) => Promise<void>;
  disableUser: (state: boolean, userId: string) => Promise<void>;
}

export const PartnerContext = createContext<IPartnerContext>({} as IPartnerContext);

export const PartnerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [partners, setPartners] = useState<Partner[]>([]);
  const [merchants, setMerchants] = useState<IUser[]>([]);

  const getPartners = async () => {
    const data = await fetchData(`users/partners`);
    setPartners(data);
  };

  const getMerchants = async () => {
    const data = await fetchData('users/merchants');
    setMerchants(data);
  };

  const createUser = ({ email, password, role }: CreateUserData) =>
    fetchData('auth/create-user', {
      method: 'POST',
      body: JSON.stringify({ email, password, role }),
    });

  const createPartner = ({ email, password }: CreatePartnerData) =>
    createUser({
      email,
      password,
      role: Role.Partner,
    });

  const createMerchant = ({ email, password }: CreatePartnerData) =>
    createUser({
      email,
      password,
      role: Role.Merchant,
    });

  const deletePartner = async (id: string) =>
    fetchData(`users/${id}`, {
      method: 'DELETE',
    });

  const deleteMerchant = async (id: string) =>
    fetchData(`users/${id}`, {
      method: 'DELETE',
    });

  const disableUser = async (state: boolean, userId: string) =>
    fetchData(`users/${userId}/disable`, {
      method: 'PATCH',
      body: JSON.stringify({ disabled: state }),
    });

  return (
    <PartnerContext.Provider
      value={{
        partners,
        merchants,
        createPartner,
        deletePartner,
        getPartners,
        createMerchant,
        deleteMerchant,
        getMerchants,
        disableUser,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};
