import { FC, useContext, useState } from 'react';
import { Button, InlineNotification, PaperWrapper, PasswordField } from '../../components';
import { AuthContext, fetchData, useNotification } from '../../utils';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const WhiteBitCreds: FC = () => {
  const { user, fetchUser } = useContext(AuthContext);
  const [securityKey, setSecurityKey] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { openNotification } = useNotification();
  const { t } = useTranslation('translation', { keyPrefix: 'WhiteBitCreds' });

  if (!user) return null;

  const handleSecurityKeyChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setErrors([]);
    setSecurityKey(e.target.value);
  };
  const handleApiKeyChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setErrors([]);
    setApiKey(e.target.value);
  };

  const handleWhitebitCredentials = async () => {
    if (!user) return;

    if (!securityKey || !apiKey) {
      return setErrors([t('notEmptyKeysMessage')]);
    }
    try {
      setIsLoading(true);
      await fetchData(`users/${user.id}/credentials`, {
        method: 'PATCH',
        body: JSON.stringify({ securityKey, apiKey }),
      });
      await fetchUser?.();
      setSecurityKey('');
      setApiKey('');
      openNotification(t('credentialsAddedMessage'));
    } catch (error) {
      const messagesArr = Array.isArray(error.message) ? error.message : [error.message];
      setErrors(messagesArr);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PaperWrapper>
      {user.hasWhitebitCredentials && (
        <InlineNotification open={user.hasWhitebitCredentials} severity="info">
          {t('hasWhitebitCredentialsMessage')}
        </InlineNotification>
      )}
      <h2>{`${user?.hasWhitebitCredentials ? t('title.update') : t('title.add')} ${t('title.text')}`}</h2>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs={12} md={5}>
          <PasswordField
            value={securityKey}
            onChange={handleSecurityKeyChange}
            fullWidth
            label={t('fields.securityKey')}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <PasswordField value={apiKey} onChange={handleApiKeyChange} fullWidth label={t('fields.apiKey')} />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button onClick={handleWhitebitCredentials} sx={{ width: '100%' }} isLoading={isLoading}>
            {user.hasWhitebitCredentials ? t('buttons.update') : t('buttons.add')}
          </Button>
        </Grid>
        {!!errors.length && (
          <Grid item xs={12}>
            <InlineNotification open list={errors} severity="error" />
          </Grid>
        )}
      </Grid>
    </PaperWrapper>
  );
};
