import { FC, useContext, useEffect, useState } from 'react';
import { AccountListItem, CreateAccount, InlineNotification, Loader, PaperWrapper } from '../../components';
import { PageTemplate } from '../PageTemplate/PageTemplate';
import { AccountContext, ModalContext, RoutesPath } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { List } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AccountsPage: FC = () => {
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);
  const [deleteErrors, setDeleteErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { accounts, deleteAccount, getAccounts } = useContext(AccountContext);
  const { setModalState } = useContext(ModalContext);
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.accounts' });

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      setFetchErrors([]);
      setIsLoading(true);
      await getAccounts();
    } catch ({ message, errors }) {
      const messagesArr = Array.isArray(message) ? message : [message];
      setFetchErrors(errors ?? messagesArr);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAccount = async (id: string) => {
    try {
      setDeleteErrors([]);
      setIsLoading(true);
      await deleteAccount(id);
    } catch ({ message, errors }) {
      const messagesArr = Array.isArray(message) ? message : [message];
      setDeleteErrors(errors ?? messagesArr);
    } finally {
      setIsLoading(false);
    }
  };

  const openDeleteDialog = (email: string, id: string) =>
    setModalState({
      opened: true,
      title: t('deleteAccountModal.title', { email }) as string,
      body: t('deleteAccountModal.body') as string,
      actions: [
        {
          text: t('deleteAccountModal.buttons.delete') as string,
          variant: 'contained',
          color: 'error',
          onClick: () => {
            handleDeleteAccount(id);
            setModalState({ opened: false });
          },
        },
        {
          text: t('deleteAccountModal.buttons.cancel') as string,
          variant: 'contained',
          onClick: () => setModalState({ opened: false }),
        },
      ],
    });

  return (
    <PageTemplate pageTitle={t('title') as string}>
      <CreateAccount />
      {isLoading ? (
        <Loader />
      ) : (
        <PaperWrapper>
          <InlineNotification open={!!fetchErrors.length} list={fetchErrors} severity="error" />
          <InlineNotification open={!!deleteErrors?.length} list={deleteErrors} ttl={5000} severity="error" />
          {accounts.length ? (
            <List>
              {accounts
                .sort((a, b) => a.email.localeCompare(b.email))
                .map(({ email, id }) => (
                  <AccountListItem
                    key={id}
                    email={email}
                    disableActions={isLoading}
                    handleDelete={() => openDeleteDialog(email, id)}
                    onAccountClick={() => navigate(`/${RoutesPath.ACCOUNTS}/${id}`)}
                  />
                ))}
            </List>
          ) : (
            !fetchErrors.length && <InlineNotification open list={[t('emptyAccountsMessage')]} severity="info" />
          )}
        </PaperWrapper>
      )}
    </PageTemplate>
  );
};
