import { createContext, FC, PropsWithChildren, useState } from 'react';
import { fetchData } from './api';

export interface Code {
  ticker: string;
  amount: string;
  status: string;
  code: string;
  external_id: string;
  date: number;
  password: string;
  activatedAt: string;
  activatedBy: string;
}

export type SelectedCode = Partial<Pick<Code, 'code' | 'password'>>;

export interface Account {
  id: string;
  email: string;
  codes: Code[];
}

interface CreateAccountData {
  email: string;
  apiKey: string;
  securityKey: string;
}

interface IAccountContext {
  accounts: Account[];
  createAccount: (accountData: CreateAccountData) => void;
  deleteAccount: (id: string) => Promise<void>;
  getAccounts: () => void;
}

export const AccountContext = createContext<IAccountContext>({} as IAccountContext);

export const AccountProvider: FC<PropsWithChildren> = ({ children }) => {
  const [accounts, setAccounts] = useState<Account[]>([]);

  const getAccounts = async () => {
    const accounts = await fetchData(`accounts`);
    setAccounts(accounts);
  };

  const createAccount = async ({ email, apiKey, securityKey }: CreateAccountData) => {
    await fetchData('accounts/cerate', {
      method: 'POST',
      body: JSON.stringify({ email, apiKey, securityKey }),
    });
    await getAccounts();
  };

  const deleteAccount = async (id: string) => {
    await fetchData(`accounts/${id}`, {
      method: 'DELETE',
    });
    await getAccounts();
  };

  return (
    <AccountContext.Provider value={{ accounts, createAccount, deleteAccount, getAccounts }}>
      {children}
    </AccountContext.Provider>
  );
};
