import { FC, PropsWithChildren, useContext } from 'react';
import { Alert, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Header, NavBar } from '../../components';
import { AuthContext, Role, RoutesPath } from '../../utils';
import { Link, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  content: {
    padding: 20,
  },
  title: {
    margin: '0 0 30px',
  },
}));

interface PageTemplateProps extends PropsWithChildren {
  pageTitle?: string;
}

export const PageTemplate: FC<PageTemplateProps> = ({ children, pageTitle }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();
  const {
    classes: { content, title },
  } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'PageTemplate' });

  return (
    <div>
      <Header />
      <div className={content}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch">
          {matches && (
            <Grid item xs={12} md={3}>
              <NavBar />
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            {user?.disabled && (
              <Alert color="error" sx={{ marginBottom: '30px' }}>
                {t('deactivatedAccountMessage')}
              </Alert>
            )}
            {user?.role === Role.Admin && !user?.hasWhitebitCredentials && pathname !== `/${RoutesPath.SETTINGS}` && (
              <Alert color="info" sx={{ marginBottom: '30px' }}>
                <Trans components={{ l: <Link to={`/${RoutesPath.SETTINGS}`} /> }}>
                  {t('emptyCredentialsMessage')}
                </Trans>
              </Alert>
            )}
            {!user?.disabled && !user?.twoFactorEnabled && pathname !== `/${RoutesPath.SETTINGS}` && (
              <Alert color="info" sx={{ marginBottom: '30px' }}>
                <Trans components={{ l: <Link to={`/${RoutesPath.SETTINGS}`} /> }}>{t('turnOn2FAMessage')}</Trans>
              </Alert>
            )}
            {pageTitle && <h1 className={title}>{pageTitle}</h1>}
            {children}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export const SimplePageTemplate: FC<PageTemplateProps> = ({ children, pageTitle }) => {
  const {
    classes: { content, title },
  } = useStyles();

  return (
    <div>
      <Header />
      <div className={content}>
        {pageTitle && <h1 className={title}>{pageTitle}</h1>}
        {children}
      </div>
    </div>
  );
};
