import { FC, useContext, useEffect, useState } from 'react';
import { CreateUserEntity, PaperWrapper, InlineNotification, Loader } from '../../components';
import { PageTemplate } from '../PageTemplate/PageTemplate';
import { CreatePartnerData, ModalContext, PartnerContext, PartnerProvider, useNotification } from '../../utils';
import { List } from '@mui/material';
import { AccountListItem } from '../../components/AccountsList/AccountListItem';
import { useTranslation } from 'react-i18next';

const PartnersPageComponent: FC = () => {
  const [createLoading, setCreateLoading] = useState(false);
  const [createErrors, setCreateErrors] = useState<string[]>([]);
  const [actionsLoading, setActionsLoading] = useState(false);
  const [actionsErrors, setActionsErrors] = useState<string[]>([]);
  const [partnersLoading, setPartnersLoading] = useState(true);
  const [partnersErrors, setPartnersErrors] = useState<string[]>([]);
  const { createPartner, partners, deletePartner, getPartners, disableUser } = useContext(PartnerContext);
  const { setModalState } = useContext(ModalContext);
  const { openNotification } = useNotification();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.partners' });

  const fetchData = async () => {
    try {
      setPartnersLoading(true);
      await getPartners();
    } catch ({ message }) {
      setPartnersErrors(typeof message === 'string' ? [message] : message);
    } finally {
      setPartnersLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreatePartner = async (data: CreatePartnerData) => {
    try {
      setCreateErrors([]);
      setCreateLoading(true);
      await createPartner(data);
      openNotification('Partner created!');
      await getPartners();
    } catch ({ message, errors }) {
      const msg = typeof message === 'string' ? [message] : message;
      setCreateErrors(errors ?? msg);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleDisabledChange = async (state: boolean, userId: string) => {
    try {
      setActionsErrors([]);
      setActionsLoading(true);
      await disableUser(state, userId);
      await getPartners();
    } catch ({ message, errors }) {
      const msg = typeof message === 'string' ? [message] : message;
      setActionsErrors(errors ?? msg);
    } finally {
      setActionsLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setActionsLoading(true);
      await deletePartner(id);
      await getPartners();
    } catch ({ message, errors }) {
      const msg = typeof message === 'string' ? [message] : message;
      setActionsErrors(errors ?? msg);
    } finally {
      setActionsLoading(false);
    }
  };

  const openDeleteDialog = (email: string, id: string, modalBody: string) =>
    setModalState({
      opened: true,
      title: t('deletePartnerModal.title', { email }) as string,
      body: modalBody,
      actions: [
        {
          text: t('deletePartnerModal.buttons.delete', { email }) as string,
          variant: 'contained',
          color: 'error',
          onClick: () => {
            handleDelete(id);
            setModalState({ opened: false });
          },
        },
        {
          text: t('deletePartnerModal.buttons.cancel', { email }) as string,
          variant: 'contained',
          onClick: () => setModalState({ opened: false }),
        },
      ],
    });

  const openDeletePartnerDialog = (email: string, id: string) =>
    openDeleteDialog(email, id, t('deletePartnerModal.deletePartnerBody'));
  const openDeleteMerchantDialog = (email: string, id: string) =>
    openDeleteDialog(email, id, t('deletePartnerModal.deleteMerchantBody'));

  return (
    <PageTemplate pageTitle={t('title') as string}>
      <CreateUserEntity
        createButtonText={t('createButtonText')}
        errors={createErrors}
        isLoading={createLoading}
        createEntity={handleCreatePartner}
      />
      {partnersLoading ? (
        <Loader />
      ) : (
        <PaperWrapper>
          <InlineNotification open={!!partnersErrors.length} list={partnersErrors} severity="error" />
          <InlineNotification open={!!actionsErrors?.length} list={actionsErrors} ttl={5000} severity="error" />
          {partners.length ? (
            <List>
              {partners
                .sort((a, b) => a.email.localeCompare(b.email))
                .map(({ email, id, disabled, merchants }) => (
                  <AccountListItem
                    key={id}
                    email={email}
                    disableActions={actionsLoading}
                    expandable
                    handleDelete={() => openDeletePartnerDialog(email, id)}
                    disabled={disabled}
                    handleDisabledChange={() => handleDisabledChange(!disabled, id)}
                    padding="0"
                  >
                    {!!merchants.length
                      ? merchants
                          .sort((a, b) => a.email.localeCompare(b.email))
                          .map(({ email: merchantEmail, id: merchantId, disabled: merchantDisabled }) => (
                            <AccountListItem
                              key={merchantId}
                              email={merchantEmail}
                              disableActions={actionsLoading}
                              handleDelete={() => openDeleteMerchantDialog(merchantEmail, merchantId)}
                              disabled={merchantDisabled}
                              handleDisabledChange={() => handleDisabledChange(!merchantDisabled, merchantId)}
                              padding="60px"
                            />
                          ))
                      : null}
                  </AccountListItem>
                ))}
            </List>
          ) : (
            !partnersErrors.length && <InlineNotification open list={[t('emptyPartnersMessage')]} severity="info" />
          )}
        </PaperWrapper>
      )}
    </PageTemplate>
  );
};

export const PartnersPage: FC = () => (
  <PartnerProvider>
    <PartnersPageComponent />
  </PartnerProvider>
);
