import { FC } from 'react';
import { CopyAll } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TwoFAEnableViewProps } from './types';
import { TwoFAVerifyForm } from './TwoFAVerifyForm';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  dataWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  imageBox: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      flexBasis: '200px',
    },

    [theme.breakpoints.down('md')]: {
      margin: '0 auto 20px',
      width: '200px',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
}));

export const TwoFAEnableView: FC<TwoFAEnableViewProps> = ({
  twoFAData,
  verificationError,
  onTokenChange,
  handleEnable2FA,
  token,
  isVerificationLoading,
  disabled,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'TwoFactorAuth' });
  const {
    classes: { dataWrapper, imageBox, image },
  } = useStyles();

  return (
    <div className={dataWrapper}>
      <div className={imageBox}>
        <img src={twoFAData.qrCode} alt="2fa qrcode" className={image} />
      </div>
      <div>
        <p>{t('enableDescription')}</p>
        <TextField
          fullWidth
          label={t('fields.code')}
          defaultValue={twoFAData.two_factor_secret_temp}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(twoFAData.two_factor_secret_temp);
                  }}
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <CopyAll />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <p>{t('enterCode')}</p>
        <TwoFAVerifyForm
          verificationError={verificationError}
          onTokenChange={onTokenChange}
          handleProcess2FA={handleEnable2FA}
          token={token}
          isVerificationLoading={isVerificationLoading}
          buttonText={t('buttons.enable')}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
