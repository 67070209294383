import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Drawer, DrawerProps, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface MobileDrawerProps extends PropsWithChildren {
  openIcon: ReactNode;
  closeIcon: ReactNode;
  drawerProps?: Omit<DrawerProps, 'open' | 'onClose'>;
}

const useStyles = makeStyles()(theme => {
  return {
    menuOpener: {
      marginLeft: 20,
    },
  };
});

export const MobileDrawer: FC<MobileDrawerProps> = ({ openIcon, closeIcon, children, drawerProps }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const {
    classes: { menuOpener },
  } = useStyles();

  useEffect(() => {
    setMobileMenuOpened(false);
  }, [matches]);

  return matches ? (
    <>
      <IconButton className={menuOpener} color="primary" onClick={() => setMobileMenuOpened(!mobileMenuOpened)}>
        {mobileMenuOpened ? closeIcon : openIcon}
      </IconButton>
      <Drawer {...drawerProps} open={mobileMenuOpened} onClose={() => setMobileMenuOpened(false)}>
        {children}
      </Drawer>
    </>
  ) : null;
};
