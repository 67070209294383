import { FC, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Button, InlineNotification, PasswordField } from '../../components';
import { RoutesPath, fetchData } from '../../utils';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const useStyles = makeStyles()(() => ({
  textField: {
    marginBottom: 20,
  },
}));

export const ResetPasswordForm: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState('');
  const {
    classes: { textField },
  } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.resetPassword' });

  const handleChangePassword = async () => {
    if (!password || !confirmPassword) {
      return setErrors([t('emptyPassworMessage')]);
    }

    if (password !== confirmPassword) {
      return setErrors([t('equalPasswordsMessage')]);
    }

    try {
      setIsLoading(true);
      await fetchData('auth/reset-password', {
        method: 'PUT',
        body: JSON.stringify({ password: password, token: searchParams.get('token'), id: searchParams.get('id') }),
      });
      setSuccessMessage(t('passwordChangedMessage') as string);
      setTimeout(() => {
        navigate(`/${RoutesPath.LOGIN}`);
      }, 3000);
    } catch ({ message, errors, statusCode }) {
      if (statusCode === 429) {
        setErrors([i18next.t('messages.rateLimit', { minutes: message })]);
        return;
      }

      const msg = typeof message === 'string' ? [message] : message;
      setErrors(errors ?? msg);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    setConfirmPassword(e.target.value);
  };

  if (!searchParams.get('token') || !searchParams.get('id')) {
    return (
      <>
        <InlineNotification open severity="error">
          {t('badResetPasswordLink')}
        </InlineNotification>
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <Link to={`/${RoutesPath.LOGIN}`}>{t('buttons.home')}</Link>
        </div>
      </>
    );
  }

  return (
    <>
      <PasswordField
        label={t('fields.password')}
        className={textField}
        value={password}
        onChange={handlePasswordChange}
        fullWidth
      />
      <PasswordField
        className={textField}
        value={confirmPassword}
        label={t('fields.confirmPassword')}
        onChange={handleConfirmPasswordChange}
        fullWidth
      />
      {!!errors.length && <InlineNotification open list={errors} severity="error" />}
      {successMessage && (
        <Alert severity="success" sx={{ width: '100%', margin: '0 0 20px' }}>
          {successMessage}
        </Alert>
      )}
      <Button onClick={handleChangePassword} fullWidth isLoading={isLoading}>
        {t('buttons.changePassword')}
      </Button>
      <div style={{ textAlign: 'center', padding: '20px 0' }}>
        <Link to={`/${RoutesPath.LOGIN}`}>{t('buttons.home')}</Link>
      </div>
    </>
  );
};
