import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Alert, InputBaseComponentProps, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Button, PaperWrapper } from '../../components';
import { AuthContext } from '../../utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(() => ({
  codeField: {
    width: '56px',
    margin: '0 3px',
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  codeFieldsBlock: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 15px',
  },
}));

const inputProps: InputBaseComponentProps = {
  pattern: 'd+',
  min: '0',
  max: '1',
  style: {
    textAlign: 'center',
  },
};

interface TwoFATokenFormProps {
  email: string;
}

export const TwoFATokenForm: FC<TwoFATokenFormProps> = ({ email }) => {
  const [token, setToken] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const [isVerificationIsLoading, setVerificationIsLoading] = useState(false);
  const inputRef = useRef<Record<string, HTMLDivElement | null>>({});
  const { verify2FAToken } = useContext(AuthContext);
  const { t } = useTranslation('translation', { keyPrefix: 'TwoFATokenForm' });

  const {
    classes: { codeField, codeFieldsBlock },
  } = useStyles();

  const handle2FA = async (token: string) => {
    const isTokenValid = /^\d{6}$/gm.test(token);
    if (!isTokenValid) {
      return setVerificationError(t('validationMessage') as string);
    }

    try {
      setVerificationIsLoading(true);
      await verify2FAToken?.(token, email);
    } catch (error) {
      setVerificationError(error.message);
    } finally {
      setVerificationIsLoading(false);
      setToken('');
    }
  };

  useEffect(() => {
    inputRef.current[token.length]?.focus();

    if (token.length === 6) {
      handle2FA(token);
    }
  }, [token]);

  const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (token.length <= 5) {
      setToken(oldToken => oldToken + e.target.value);
    }
  };

  const handleTokenDelete = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Backspace') {
      setToken(token.slice(0, -1));
    }
  };

  return (
    <PaperWrapper>
      <div className={codeFieldsBlock}>
        {Array.from(Array(6)).map((el, index) => (
          <TextField
            key={index}
            value={token[index] || ''}
            className={codeField}
            type="number"
            variant="outlined"
            onChange={handleTokenChange}
            onKeyDown={handleTokenDelete}
            inputRef={el => {
              inputRef.current[index] = el;
            }}
            inputProps={{ ...inputProps, autoFocus: index === 0 }}
          />
        ))}
      </div>
      {verificationError && (
        <Alert color="error" sx={{ marginBottom: '15px' }}>
          {verificationError}
        </Alert>
      )}
      <Button fullWidth isLoading={isVerificationIsLoading} onClick={() => handle2FA(token)}>
        {t('buttons.continue')}
      </Button>
    </PaperWrapper>
  );
};
