import { FC, useContext } from 'react';
import { AuthContext, Role } from '../../utils';
import { PageTemplate } from '../PageTemplate/PageTemplate';
import { TwoFactorAuth } from './TwoFactorAuth';
import { ChangePassword } from './ChangePassword';
import { WhiteBitCreds } from './WhiteBitCreds';
import { useTranslation } from 'react-i18next';

export const SettingsPage: FC = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings' });

  const isAdmin = user?.role === Role.Admin;

  return (
    <PageTemplate pageTitle={t('title') as string}>
      {isAdmin && <WhiteBitCreds />}
      <TwoFactorAuth />
      {isAdmin && <ChangePassword />}
    </PageTemplate>
  );
};
