import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { SimplePageTemplate } from '../PageTemplate/PageTemplate';
import { RoutesPath } from '../../utils';
import { useTranslation } from 'react-i18next';

export const PageNotFound: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.notFound' });

  return (
    <SimplePageTemplate pageTitle={t('title') as string}>
      <Button component={Link} to={RoutesPath.HOME} size="small">
        {t('buttons.home')}
      </Button>
    </SimplePageTemplate>
  );
};
