import { FC, useContext, useReducer } from 'react';
import { Alert } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Button, PaperWrapper, PasswordField } from '../../../components';
import { AuthContext, fetchData, useNotification } from '../../../utils';
import { Actions, initialState, reducer } from './reducer';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(() => ({
  textField: {
    margin: '0 10px 20px 0',
    width: '250px',
  },
}));

export const ChangePassword: FC = () => {
  const { user, setUser } = useContext(AuthContext);
  const [{ oldPass, newPass, confirmNewPass, matchPasswordError, error, successMessage }, dispatch] = useReducer(
    reducer,
    initialState,
  );
  const {
    classes: { textField },
  } = useStyles();
  const { openNotification } = useNotification();
  const { t } = useTranslation('translation', { keyPrefix: 'ChangePassword' });

  const onInputChange = (eventType: keyof typeof Actions, value: string) => {
    dispatch({ type: 'resetErrors' });
    dispatch({
      type: eventType,
      payload: value,
    });
  };

  const onPasswordChange = async () => {
    dispatch({ type: 'resetErrors' });

    if (newPass !== confirmNewPass) {
      return dispatch({
        type: 'setMatchPasswordError',
        payload: t('equalPasswordsMessage') as string,
      });
    }

    if (!newPass || !confirmNewPass || !oldPass) {
      return dispatch({
        type: 'setError',
        payload: t('requiredFieldsMessage') as string,
      });
    }
    try {
      const response = await fetchData(`users/${user?.id}/password`, {
        method: 'PATCH',
        body: JSON.stringify({ password: newPass, oldPassword: oldPass }),
      });

      if (response) {
        setUser?.(response);
        dispatch({
          type: 'setSuccessMessage',
          payload: t('updatedPasswordMessage') as string,
        });
        openNotification(t('updatedPasswordMessage'));
      }
    } catch (error) {
      dispatch({
        type: 'setError',
        payload: error.errors ?? error.message,
      });
    }
  };

  return (
    <PaperWrapper>
      <PasswordField
        value={oldPass}
        onChange={e => onInputChange('setOldPass', e.target.value)}
        sx={{ marginRight: '20px' }}
        label={t('fields.oldPassword')}
        className={textField}
      />
      <PasswordField
        error={!!matchPasswordError}
        onChange={e => onInputChange('setNewPass', e.target.value)}
        value={newPass}
        sx={{ marginRight: '20px' }}
        label={t('fields.newPassword')}
        helperText={matchPasswordError}
        className={textField}
      />
      <PasswordField
        error={!!matchPasswordError}
        onChange={e => onInputChange('setConfirmNewPass', e.target.value)}
        value={confirmNewPass}
        sx={{ marginRight: '20px' }}
        label={t('fields.confirmPassword')}
        helperText={matchPasswordError}
        className={textField}
      />
      {error && (
        <Alert color="error" sx={{ marginBottom: '20px' }}>
          {error}
        </Alert>
      )}
      {successMessage && (
        <Alert color="success" sx={{ marginBottom: '20px' }}>
          {successMessage}
        </Alert>
      )}
      <div>
        <Button onClick={onPasswordChange}>{t('buttons.change')}</Button>
      </div>
    </PaperWrapper>
  );
};
