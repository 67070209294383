import { PropsWithChildren, FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    maxWidth: 400,
    height: '100vh',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  scroll: {
    overflow: 'auto',
    padding: '20px 15px',
  },
}));

export const CenteredPageTemplate: FC<PropsWithChildren> = ({ children }) => {
  const {
    classes: { root, scroll },
  } = useStyles();

  return (
    <div className={root}>
      <div className={scroll}>{children}</div>
    </div>
  );
};
