import { FC } from 'react';
import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export type Order = 'asc' | 'desc';

export interface HeadCell {
  id: string;
  numeric: boolean;
  label: string;
  sortable?: boolean;
}

export interface EnhancedTableProps {
  numSelected?: number;
  onRequestSort: (property: string) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
  padding?: boolean;
}

export const EnhancedTableHead: FC<EnhancedTableProps> = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  padding,
}) => {
  const createSortHandler = (property: string) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {padding && <TableCell padding="checkbox" sx={{ borderWidth: '3px' }} />}
        {typeof numSelected === 'number' && (
          <TableCell padding="checkbox" sx={{ borderWidth: '3px' }}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headCells.map(({ id, label, sortable = true }, i) => (
          <TableCell
            sx={{ borderWidth: '3px' }}
            key={id}
            align={i === 0 ? 'left' : 'right'}
            sortDirection={orderBy === id ? order : false}
          >
            {sortable ? (
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : 'asc'}
                onClick={createSortHandler(id)}
              >
                {label}
                {orderBy === id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const SimpleTableHead: FC<Pick<EnhancedTableProps, 'headCells' | 'padding'>> = ({ headCells, padding }) => {
  return (
    <TableHead>
      <TableRow>
        {padding && <TableCell padding="checkbox" sx={{ borderWidth: '3px' }} />}
        {headCells.map(({ id, label, sortable = true }, i) => (
          <TableCell sx={{ borderWidth: '3px' }} key={id} align={i === 0 ? 'left' : 'right'}>
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
