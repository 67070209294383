import { FC, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Button, InlineNotification } from '../../components';
import { RoutesPath, fetchData } from '../../utils';
import { Link, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';

const useStyles = makeStyles()(() => ({
  textField: {
    marginBottom: 20,
  },
  text: {
    h2: {
      margin: '0 0 20px',
    },
  },
}));

export const ForgotPasswordForm: FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState('');
  const {
    classes: { textField, text },
  } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'ForgotPasswordForm' });

  const handleResetPassword = async () => {
    if (!email) {
      return setErrors([t('emptyEmailMessage')]);
    }

    try {
      setIsLoading(true);
      await fetchData('auth/reset-password', {
        method: 'POST',
        body: JSON.stringify({ email, origin: window.location.origin }),
      });
      setSuccessMessage(t('messageSentMessage') as string);
      setTimeout(() => {
        navigate(`/${RoutesPath.LOGIN}`);
      }, 8000);
    } catch ({ message, errors, statusCode }) {
      if (statusCode === 429) {
        setErrors([i18next.t('messages.rateLimit', { minutes: message })]);
        return;
      }

      const msg = typeof message === 'string' ? [message] : message;
      setErrors(errors ?? msg);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    setEmail(e.target.value);
  };

  return (
    <>
      <div className={text}>
        <h2>{t('title')}</h2>
        <Trans components={{ p: <p /> }}>{t('body')}</Trans>
      </div>
      <TextField
        className={textField}
        value={email}
        type="email"
        label={t('fields.email')}
        variant="outlined"
        onChange={handleEmailChange}
        fullWidth
      />
      <InlineNotification open={!!errors.length} list={errors} severity="error" />
      {successMessage && (
        <Alert severity="success" sx={{ width: '100%', margin: '0 0 20px' }}>
          {successMessage}
        </Alert>
      )}
      <Button onClick={handleResetPassword} fullWidth isLoading={isLoading} disabled={!!successMessage}>
        {t('buttons.continue')}
      </Button>
      <div style={{ textAlign: 'center', padding: '20px 0' }}>
        <Link to={`/${RoutesPath.LOGIN}`}>{t('buttons.home')}</Link>
      </div>
    </>
  );
};
