import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(() => ({
  componentLoader: {
    padding: '20px',
    textAlign: 'center',
  },
  pageLoader: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    margin: '-20px 0 0 -20px',
  },
}));

interface LoaderProps {
  type?: 'component' | 'page';
}

export const Loader: FC<LoaderProps> = ({ type = 'component' }) => {
  const {
    classes: { componentLoader, pageLoader },
  } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'Loader' });

  return type === 'component' ? (
    <div className={componentLoader}>
      <p>{t('loadingText')}</p>
      <CircularProgress />
    </div>
  ) : (
    <CircularProgress className={pageLoader} />
  );
};
