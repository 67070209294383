import { FC } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';

interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean;
}

export const Button: FC<ButtonProps> = ({ children, variant = 'contained', size = 'large', isLoading, ...rest }) => (
  <MuiButton variant={variant} size={size} {...rest}>
    {isLoading ? <CircularProgress color="inherit" size={26} /> : children}
  </MuiButton>
);
