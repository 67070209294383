import { createContext, FC, PropsWithChildren, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface IModalContext {
  setModalState: (state: ModalState) => void;
}

interface Action {
  variant?: 'text' | 'contained' | 'outlined';
  color?: 'error' | 'inherit' | 'primary' | 'secondary' | 'success' | 'info' | 'warning';
  text: string;
  onClick: () => void;
}

interface ModalState {
  opened: boolean;
  title?: string;
  body?: string;
  onClose?: () => void;
  actions?: Action[];
}

export const ModalContext = createContext<IModalContext>({} as IModalContext);

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState>({
    opened: false,
  });

  const { opened, title, body, onClose, actions } = modalState;

  return (
    <ModalContext.Provider value={{ setModalState }}>
      {children}
      <Dialog open={opened} transitionDuration={0} onClose={onClose}>
        {title && <DialogTitle>{title}</DialogTitle>}
        {body && (
          <DialogContent>
            <DialogContentText>{body}</DialogContentText>
          </DialogContent>
        )}
        {!!actions?.length && (
          <DialogActions>
            {actions.map(({ onClick, text, ...rest }) => (
              <Button key={text} onClick={onClick} {...rest}>
                {text}
              </Button>
            ))}
          </DialogActions>
        )}
      </Dialog>
    </ModalContext.Provider>
  );
};
