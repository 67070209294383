import { FC, useContext } from 'react';
import { ListItemIcon, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { Logout, Settings } from '@mui/icons-material';
import { AuthContext, RoutesPath } from '../../utils';
import { useTranslation } from 'react-i18next';

interface AccountMenuItemsProps {
  divider?: boolean;
}

export const AccountMenuItems: FC<AccountMenuItemsProps> = ({ divider = false }) => {
  const { signout } = useContext(AuthContext);
  const { t } = useTranslation('translation', { keyPrefix: 'nav' });
  const styles = divider ? { padding: '12px 16px' } : {};

  return (
    <>
      <MenuItem component={Link} to={`/${RoutesPath.SETTINGS}`} divider={divider} sx={styles}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        {t('settings')}
      </MenuItem>
      <MenuItem onClick={signout} divider={divider} sx={styles}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {t('logout')}
      </MenuItem>
    </>
  );
};
