export const fetchData = async (url: string, options?: RequestInit) => {
  const response = await fetch(`/api/${url}`, {
    headers: { 'Content-Type': 'application/json' },
    ...options,
  });

  const data = await response.json();

  if (!response.ok) {
    throw data;
  }

  return data;
};
