import { CheckCircleOutline, InfoOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import { FC } from 'react';

interface PageNotigicationProps {
  open: boolean;
  body: string;
  severity?: AlertColor;
  onClose?: () => void;
}

export const PageNotigication: FC<PageNotigicationProps> = ({ open, onClose, body, severity }) => {
  const styles =
    severity === 'success'
      ? {
          backgroundColor: 'rgb(56, 142, 60)',
          color: 'white',
        }
      : severity === 'error'
      ? {
          backgroundColor: '#d32f2f',
          color: 'white',
        }
      : {};

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transitionDuration={0}
    >
      <Alert
        severity={severity ?? 'success'}
        sx={styles}
        iconMapping={{
          success: <CheckCircleOutline sx={{ fill: 'white' }} />,
          error: <InfoOutlined sx={{ fill: 'white' }} />,
          warning: <WarningAmberOutlined sx={{ fill: 'white' }} />,
        }}
      >
        {body}
      </Alert>
    </Snackbar>
  );
};
